import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "../../../i18n/translate"
import GenericPreview from "../generic-preview"

const PagePreview = ({
  image,
  title,
  link,
  ctaTranslatedString,
  descriptionTranslationKey,
}) => {
  const { t } = useTranslation()
  return (
    <GenericPreview
      image={image?.childImageSharp}
      imageAltTranslationKey={title}
      titleTranslationKey={title}
      descriptionTranslationKey={descriptionTranslationKey}
      linkToDetailPagePath={link}
      moreAboutThisTranslatedString={ctaTranslatedString}
    />
  )
}

PagePreview.propTypes = {
  image: PropTypes.any,
  title: PropTypes.string,
  ctaTranslatedString: PropTypes.string,
  link: PropTypes.string,
}

export default PagePreview
