import classNames from "classnames"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import React from "react"

import LocalizedLink from "../../i18n/localized-link"
import { withTranslation } from "../../i18n/translate"
import FullWidthTileContent from "../tile/full-width-tile-content/full-width-tile-content"
import Tile from "../tile/tile"
import TileContentSpacer from "../tile/tile-content-spacer/tile-content-spacer"
import "./generic-preview.sass"

class GenericPreview extends React.Component {
  constructor(props) {
    super(props)
    this.previewImageRef = React.createRef()
    this.resizeListener = () => this.handleResize()
  }

  componentDidMount() {
    window.addEventListener("resize", this.resizeListener)
    this.handleResize()
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener)
  }

  handleResize() {
    if (this.previewImageRef.current) {
      this.previewImageRef.current.style.height =
        this.previewImageRef.current.clientWidth * 0.5 + "px"
    }
  }

  render() {
    const { t, small } = this.props
    return (
      <Tile
        tileSize={"s"}
        linkTextTranslatedString={t(
          this.props.linkToProductsPageTextTranslationKey,
        )}
        linkPath={
          this.props.linkToProductsPagePath ?? this.props.linkToDetailPagePath
        }
      >
        <FullWidthTileContent>
          <LocalizedLink
            tabIndex={-1}
            to={
              this.props.linkToProductsPagePath ??
              this.props.linkToDetailPagePath
            }
          >
            <div
              ref={this.previewImageRef}
              className={"_fp-generic__image-container"}
            >
              {this.props.image && this.props.imageAltTranslationKey && (
                <GatsbyImage
                  sizes={
                    "(max-width: 600px) 100vw, (min-width: 601px) and (max-width: 900px) 50vw, 33vw"
                  }
                  image={this.props.image.gatsbyImageData}
                  className={"_fp-generic__image-container__image"}
                  alt={t(this.props.imageAltTranslationKey)}
                />
              )}
            </div>
          </LocalizedLink>
        </FullWidthTileContent>

        <TileContentSpacer />

        <LocalizedLink tabIndex={-1} to={this.props.linkToDetailPagePath}>
          <h3 className={"_fp-generic__title _fp-heading-4"}>
            {t(this.props.titleTranslationKey)}
          </h3>
        </LocalizedLink>
        {(this.props.shortDescriptionTranslationKey ||
          this.props.descriptionTranslationKey ||
          (this.props.moreAboutThisTranslatedString &&
            this.props.linkToDetailPagePath)) && (
          <>
            <TileContentSpacer />
            <div>
              {(this.props.shortDescriptionTranslationKey ||
                this.props.descriptionTranslationKey) && (
                <p
                  className={classNames("_fp-text _fp-generic__description", {
                    "_fp-generic__description--trim":
                      !this.props.shortDescriptionTranslationKey,
                  })}
                >
                  {this.props.shortDescriptionTranslationKey
                    ? t(this.props.shortDescriptionTranslationKey)
                    : t(this.props.descriptionTranslationKey)}
                </p>
              )}
              {this.props.moreAboutThisTranslatedString &&
                this.props.linkToDetailPagePath && (
                  <LocalizedLink
                    className={"_fp-text-link _fp-generic__more-link"}
                    to={this.props.linkToDetailPagePath}
                  >
                    {this.props.moreAboutThisTranslatedString}
                  </LocalizedLink>
                )}
            </div>
          </>
        )}
        {(this.props.linkToProductsPagePath ??
          this.props.linkToDetailPagePath) &&
          this.props.linkToProductsPageTextTranslationKey && (
            <TileContentSpacer />
          )}
      </Tile>
    )
  }
}

GenericPreview.propTypes = {
  image: PropTypes.any,
  imageAltTranslationKey: PropTypes.string,
  titleTranslationKey: PropTypes.string.isRequired,
  linkToProductsPageTextTranslationKey: PropTypes.string.isRequired,
  descriptionTranslationKey: PropTypes.string.isRequired,
  shortDescriptionTranslationKey: PropTypes.string,
  linkToDetailPagePath: PropTypes.string.isRequired,
  linkToProductsPagePath: PropTypes.string.isRequired,
  moreAboutThisTranslatedString: PropTypes.string.isRequired,
  small: PropTypes.bool,
}

export default withTranslation()(GenericPreview)
