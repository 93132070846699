import PropTypes from "prop-types"
import React from "react"

const FullWidthTileContent = ({ children }) => {
  return <div className={"_fp-tile__full-width-content"}>{children}</div>
}

FullWidthTileContent.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FullWidthTileContent
