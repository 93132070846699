import React from "react"

import PagePreview from "../../components/generic-preview/page-preview/page-preview"
import OverviewGrid from "../../components/overview-grid/overview-grid"
import PageHeader from "../../components/page-header/page-header"
import SEO from "../../components/seo"
import useNav from "../../hooks/useNav"
import { useTranslation } from "../../i18n/translate"

const CareerPage = ({ location }) => {
  const { t } = useTranslation()
  const careerNav = useNav({ uid: "career" })

  console.log(careerNav)
  return (
    <>
      <SEO
        pathName={location.pathname}
        titleTranslatedString={t("CAREER-META_TITLE")}
        descriptionTranslatedString={t("CAREER-META_DESCRIPTION")}
      />
      <PageHeader
        levelThreeTitle={t("CAREER-META_TITLE")}
        levelTwoTitle={t("CAREER-META_TITLE")}
      />
      <div className="_fp-global-container">
        <div className="_fp-col-12">
          <OverviewGrid marginTop={true}>
            {careerNav.children.map((c) => {
              return (
                <PagePreview
                  title={c.name.key}
                  link={c.url}
                  image={c.page_image?.image?.image?.localFile}
                  descriptionTranslationKey={c.description?.key}
                />
              )
            })}
          </OverviewGrid>
        </div>
      </div>
    </>
  )
}
export default CareerPage
