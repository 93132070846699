import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import "./overview-grid.sass"

class OverviewGrid extends React.Component {
  render() {
    return (
      <div className={"_fp-overview-grid"}>
        {this.props.topRowChildren && (
          <div className="_fp-overview-grid__top-row">
            {this.props.topRowChildren}
          </div>
        )}
        <div
          className={classNames("_fp-overview-grid__grid", {
            "_fp-overview-grid__grid--margin-top": this.props.marginTop,
          })}
        >
          {this.props.children}
        </div>
      </div>
    )
  }
}

OverviewGrid.propTypes = {
  children: PropTypes.node,
  topRowChildren: PropTypes.node,
  marginTop: PropTypes.bool,
}

OverviewGrid.defaultProps = {
  marginTop: false,
}

export default OverviewGrid
